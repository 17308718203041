/* eslint-disable camelcase */
import { Box } from '@mui/system';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import React from 'react';
import { theme } from '../../../styles/theme';
import { formatChangedDate } from '../utils';

function ActivityEventComments({ associateTimezone, eventLog }) {
  return (
    <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
      {eventLog.comments.length > 0 && (
        <Timeline
          position="left"
          className="activity-comment"
          css={{
            '.MuiTimelineItem-root::before': { flex: 0, content: 'unset' },
            padding: 0,
            margin: 0,
          }}
        >
          {eventLog.comments.map(({ created_at, created_by, comment, id }) => (
            <TimelineItem key={id}>
              <TimelineSeparator
                css={{
                  padding: '0 18px',
                }}
              >
                <TimelineDot
                  css={{
                    margin: '12px 0',
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent css={{ padding: 0, marginBottom: '16px' }}>
                <Typography
                  className="comment-datetime"
                  variant="body2"
                  css={{
                    lineHeight: '20.02px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {formatChangedDate(created_at, associateTimezone)}
                </Typography>
                <Typography
                  variant="body2"
                  css={{
                    lineHeight: '20.02px',
                    color: theme.palette.text.secondary,
                  }}
                  className="comment-name"
                >
                  {created_by.full_name} ({created_by.external_id})
                </Typography>
                <Typography
                  className="comment"
                  variant="body1"
                  css={{
                    lineHeight: '24px',
                  }}
                >
                  {comment}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Box>
  );
}

export default ActivityEventComments;
