import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { VIEWS } from 'core/config';
import PageLayout from 'components/PageLayout/PageLayout';
import Breadcrumb from 'components/BreadCrumb/BreadCrumb';
import BatchActionBar from 'components/BatchActionBar/BatchActionBar';
import { fetchFeatureFlags } from 'state/ducks/featureFlags/operations';
import BatchDetailsHeader from 'components/BatchDetails/components/BatchDetailsHeader';
import * as operations from 'state/ducks/batches/operations';
import { BatchDetailsHeaderWrapper } from 'components/BatchDetails/components/style';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import JobTable from '../JobTable/JobTable';
import Drawers from '../Home/components/Drawers';
import { setAssignmentsDetailsRowsPerPage } from '../../state/ducks/staffingWorkPage/actions';

function BatchDetailsAssignmentsContainer() {
  const gridApiRef = useGridApiRef();
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const batchDetails = useSelector(
    (state) => state.batchDetailsPage.batch.batchDetails
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isErroredBatchDetails, setIsErroredBatchDetails] = useState(false);

  const breadcrumbLinks = [
    { linkText: 'Staffing Work', href: '/' },
    { linkText: 'Batch Details', href: `/batch-details/${params.batchId}` },
  ];

  useEffect(() => {
    dispatch(fetchFeatureFlags(['showSingleModeInlineEdit']));
  }, []);

  const fetchBatchDetails = () => {
    setIsLoading(true);
    dispatch(operations.fetchBatchDetails(params.batchId))
      .then(() => {
        setIsLoading(false);
        setIsErroredBatchDetails(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsErroredBatchDetails(true);
      });
  };

  useEffect(() => {
    fetchBatchDetails(params.batchId);
  }, [params.batchId]);

  return (
    <PageLayout
      cssClassName={{
        backgroundColor: theme.palette.backgroundColor.primary,
      }}
    >
      <Box css={{ backgroundColor: theme.palette.backgroundColor.secondary }}>
        <Breadcrumb
          linkText="Staffing Work"
          detailsText="Assignments"
          breadcrumbLinks={breadcrumbLinks}
          href={VIEWS.private.home}
          cssOverride={{
            paddingLeft: '24px',
            paddingTop: '16px',
          }}
        />
        {isErroredBatchDetails && (
          <BatchDetailsHeaderWrapper>
            <Typography
              id="batch-details-error"
              variant="subtitle1"
              css={{ color: '#D32F2F', marginRight: '16px' }}
            >
              Failed to load batch title and date
            </Typography>
            <Button
              id="refresh-batch-details-btn"
              variant="outlined"
              css={{ marginLeft: '16px', width: '80px', height: '30px' }}
              onClick={fetchBatchDetails}
              disabled={isLoading}
            >
              REFRESH
            </Button>
          </BatchDetailsHeaderWrapper>
        )}
        <BatchDetailsHeader batchDetails={batchDetails} isLoading={isLoading} />
      </Box>
      <BatchActionBar />
      <JobTable
        pageName="assignmentsDetails"
        onRowsPerPageChange={(value) => {
          dispatch(setAssignmentsDetailsRowsPerPage(value));
        }}
        gridApiRef={gridApiRef}
      />
      <Drawers gridApiRef={gridApiRef} />
    </PageLayout>
  );
}

export default BatchDetailsAssignmentsContainer;
