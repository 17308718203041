import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { SecondaryNavigationMenu, SecondaryNavigationMenuItem } from './style';

const CustomNavLink = forwardRef(({ focusVisibleClassName, ...props }, ref) => (
  <NavLink ref={ref} {...props} />
));

function SecondaryNavigation({ associateId }) {
  return (
    <SecondaryNavigationMenu key="menu-list">
      <SecondaryNavigationMenuItem
        as={CustomNavLink}
        key="Contact"
        data-testid="secondary-navigation-contact"
        to={`/associates/${associateId}/contact`}
      >
        Contact
      </SecondaryNavigationMenuItem>
      <SecondaryNavigationMenuItem
        as={CustomNavLink}
        key="Work Preferences"
        to={`/associates/${associateId}/work-preferences`}
        data-testid="secondary-navigation-work-preferences"
      >
        Work Preferences
      </SecondaryNavigationMenuItem>
    </SecondaryNavigationMenu>
  );
}

export default SecondaryNavigation;
