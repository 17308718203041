/* eslint-disable camelcase */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';

import { transformTimeOffData } from 'lib/utils';
import { formatChangedDate } from 'components/AssociateWorkPreferences/utils';

import { ActivityEventInfo, ActivityEventStatus } from './style';

function AssociateUnavailableTimeEvent({ eventLog, associateTimeZone }) {
  const {
    palette: { text, divider },
  } = useTheme();
  const { unavailable_time, created_at, operation } = eventLog;

  return (
    <Box id="unavailable-time-activity-event">
      <ActivityEventInfo>
        <ActivityEventStatus>
          <Typography
            id="weekly-activity-changed-date"
            variant="body2"
            color={text.secondary}
          >
            {formatChangedDate(created_at, associateTimeZone)}
          </Typography>
          <Typography variant="body2" color={text.secondary}>
            TIME OFF REQUEST
          </Typography>
        </ActivityEventStatus>
      </ActivityEventInfo>

      <Box css={{ display: 'flex' }}>
        {(operation === 'INSERT' || unavailable_time.description?.new) && (
          <Typography
            id="time-off-insert"
            variant="subtitle3"
            color={text.primary}
          >
            {transformTimeOffData(
              unavailable_time.start_datetime
                ? unavailable_time.start_datetime.new
                : unavailable_time.actual_start_datetime,
              unavailable_time.end_datetime
                ? unavailable_time.end_datetime.new
                : unavailable_time.actual_end_datetime,
              associateTimeZone
            )}
          </Typography>
        )}
        {operation === 'UPDATE' && !unavailable_time.description && (
          <Box css={{ display: 'flex' }} id="time-off-update">
            <Typography variant="body1">
              New:{' '}
              {transformTimeOffData(
                unavailable_time.start_datetime
                  ? unavailable_time.start_datetime.new
                  : unavailable_time.actual_start_datetime,
                unavailable_time.end_datetime
                  ? unavailable_time.end_datetime.new
                  : unavailable_time.actual_end_datetime,
                associateTimeZone
              )}
            </Typography>
            <Divider
              orientation="vertical"
              css={{ margin: '0 8px', color: divider }}
            />
            <Typography variant="body1" color={text.secondary}>
              Old:{' '}
              {transformTimeOffData(
                unavailable_time.start_datetime
                  ? unavailable_time.start_datetime.old
                  : unavailable_time.actual_start_datetime,
                unavailable_time.end_datetime
                  ? unavailable_time.end_datetime.old
                  : unavailable_time.actual_end_datetime,
                associateTimeZone
              )}
            </Typography>
          </Box>
        )}
      </Box>
      {operation === 'DELETE' ? (
        <>
          <Typography
            id="time-off-insert"
            variant="subtitle3"
            color={text.primary}
          >
            {transformTimeOffData(
              unavailable_time.actual_start_datetime,
              unavailable_time.actual_end_datetime,
              associateTimeZone
            )}
          </Typography>
          <Box
            id="time-off-delete"
            css={{ display: 'flex', alignItems: 'center' }}
          >
            <CancelIcon color="error" css={{ width: '16px', height: '16px' }} />
            <Typography
              variant="subtitle3"
              css={{ color: '#D32F2F', marginLeft: '4px' }}
            >
              Removed
            </Typography>
          </Box>
        </>
      ) : (
        <Typography
          id="time-off-description"
          variant="body1"
          color={text.secondary}
        >
          {unavailable_time.description ? unavailable_time.description.new : ''}
        </Typography>
      )}
    </Box>
  );
}

export default AssociateUnavailableTimeEvent;
