import { isEqual } from 'lodash';
import { DEFAULT_SELECTED_FILTERS } from '../../../components/Filters/Filters';
import { FILTER_SIBLINGS } from '../../../components/Filters/constants';

const convertFiltersMapToArrays = (updatedFilters) =>
  Object.entries(updatedFilters).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: Array.from(value),
    }),
    {}
  );

export const removeFiltersEqualToDefault = (filters) =>
  Object.entries(filters).reduce((accumulator, [key, value]) => {
    const defaultValue = DEFAULT_SELECTED_FILTERS[key];
    const siblingKey = FILTER_SIBLINGS[key];
    const siblingValue = filters[siblingKey];
    const siblingDefaultValue = DEFAULT_SELECTED_FILTERS[siblingKey];
    return {
      ...accumulator,
      [key]:
        isEqual(value, defaultValue) &&
        isEqual(siblingValue, siblingDefaultValue)
          ? new Map()
          : value,
    };
  }, {});

export const mapFiltersForCache = (filters) =>
  convertFiltersMapToArrays(removeFiltersEqualToDefault(filters));
