import * as React from 'react';
import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';

import { Box, Chip, Typography } from '@mui/material';
import { VIEWS } from 'core/config';
import { useDispatch, useSelector } from 'react-redux';
import MenuLink from './MenuLink';
import { getNumberOfUnreadAvailabilityChanges } from '../../../state/ducks/core/operations';
import auth from '../../../services/auth';
import { updateSelectedManager } from '../../../state/ducks/manager/actions';

function Tabs() {
  const dispatch = useDispatch();
  const isActive =
    useMatch('/batch-details/:batchId') ||
    useMatch('/batch-details/:batchId/assignments');

  const numberOfUnreadItems = useSelector(
    (state) => state.core.numberOfUnreadItems
  );
  const selectedManager = useSelector((state) => state.manager);

  useEffect(() => {
    if (!selectedManager.id) {
      const currentUser = auth.getCurrentUser();
      dispatch(updateSelectedManager(currentUser));
    }
  }, []);

  useEffect(() => {
    if (selectedManager.id) {
      dispatch(getNumberOfUnreadAvailabilityChanges(selectedManager.id));
    }
  }, [selectedManager]);

  return (
    <Box
      css={{ display: 'flex', alignItems: 'center', marginLeft: '30px' }}
      id="showTabs"
      key="menu-list"
    >
      <MenuLink
        id="staffing_work"
        href={VIEWS.private.home}
        isActive={isActive}
      >
        <Typography variant="subtitle1">Staffing Work</Typography>
      </MenuLink>
      <MenuLink id="associates" href={VIEWS.private.associates}>
        <Typography variant="subtitle1">Associates</Typography>
        {numberOfUnreadItems > 0 && (
          <Chip
            label={numberOfUnreadItems}
            id="unread-associates-chip"
            variant="filled"
            size="small"
            css={{
              backgroundColor: 'white',
              marginLeft: '8px',
              color: 'black',
            }}
          />
        )}
      </MenuLink>
      <MenuLink id="help" href={VIEWS.private.help}>
        <Typography variant="subtitle1">Help</Typography>
      </MenuLink>
    </Box>
  );
}
export default Tabs;
