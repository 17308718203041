import React, { useEffect, useState } from 'react';

import { getJobDetails } from 'services/jobs';

import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  TablePagination,
  Typography,
} from '@mui/material';
import { GridRow } from '@mui/x-data-grid-pro';
import DataGridErrorView from 'components/DataGridErrorView/DataGridErrorView';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { rowsPerPageOptions, sortMapping } from '../../constants/index';
import { concatenateAndPrepend, convertMinsToHrsMins } from '../../lib/utils';
import JobDateInfo from './components/JobDateInfo';
import JobID from './components/JobID';
import JobTimeInfo from './components/JobTimeInfo';
import JobAddress from './components/JobAddress';
import JobStatus from './components/JobStatus';
import { filterDrawerWidth } from '../../styles/theme';
import Associate from './components/Associate';
import { StyledDataGrid } from './style';
import {
  setBatchId,
  setBatchJobs,
  setIncludedSourceSystems,
  setIncludesAutoStaffedJob,
  setIsSelectAllPages,
  setIsSelectAllPagesLoading,
  setPageOfJobs,
  setSelectAllPageCount,
} from '../../state/ducks/staffingWorkPage/actions';
import {
  determineFooterCount,
  filterJobs,
  getBatchDialog,
  removeDuplicates,
  updateSingleRow,
} from './utils';
import { setBatch } from '../../state/ducks/dialog/actions';
import { DEFAULT_SORT_ORDERING } from '../../state/ducks/staffingWorkPage/constants';
import * as operations from '../../state/ducks/staffingWorkPage/operations';
import { determineLabelDisplayRows } from '../AssociateDetails/utils';
import { abort } from '../../state/ducks/abort/operations';

function JobTable({
  pageName,
  onRowsPerPageChange,
  gridApiRef,
  hasFilters = false,
}) {
  const { batchId: editBatchId } = useParams();

  const isOpen = useSelector((state) => state.filters.visibility.isOpen);
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const rowsPerPage = useSelector(
    (state) => state.staffingWorkPage.cache[`${pageName}RowsPerPage`]
  );
  const { centralMode, ...otherSelectedFilterItems } = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const filterModes = useSelector((state) => state.filters.filterModes);
  const isBatchMode = Array.from(centralMode.values())[0] === 'batch';
  const isAutoMode = Array.from(centralMode.values())[0].startsWith('auto');
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';
  const isRowSelectionEnabled = !isSingleMode;

  const {
    batchJobs,
    isSelectAllPages,
    isSelectAllPagesLoading,
    selectAllPagesCount,
    batchId,
  } = useSelector((state) => state.staffingWorkPage.batch);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(DEFAULT_SORT_ORDERING.join(','));
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [rows, setRows] = useState([]);
  const [jobsCount, setJobsCount] = useState(0);
  const [isJobsCountLoading, setIsJobsCountLoading] = useState(false);
  const [jobCountError, setJobCountError] = useState(false);

  const dispatch = useDispatch();
  const featureFlags = useSelector((state) => state.featureFlags);
  const pageOfJobs = useSelector((state) => state.staffingWorkPage.pageOfJobs);
  const showSingleModeInlineEdit = 'showSingleModeInlineEdit';

  const determineCellClassName = ({
    value: {
      advantage_source_system_name: advantageSourceSystemName,
      status_central: status,
    },
  }) => {
    const isCanceled = status === 'Canceled';
    const isCompleted = status === 'Completed';
    const isDisabled = isCanceled || isCompleted;

    const isSingleEditModeEnabled =
      featureFlags[showSingleModeInlineEdit] && isSingleMode;
    const isEditDrawerEnabled =
      isSingleEditModeEnabled &&
      advantageSourceSystemName === 'JET' &&
      !isDisabled;

    return isEditDrawerEnabled ? 'edit-cell' : undefined;
  };

  const getUpdatedJob = (id) => {
    getJobDetails(id).then((updatedJob) => {
      if (rows) {
        updateSingleRow(gridApiRef, updatedJob);
      }
    });
  };

  const fetchPageOfJobs = () => {
    dispatch(setPageOfJobs([]));
    setIsLoading(true);
    dispatch(
      operations.fetchPageOfJobs({
        batchId: editBatchId,
        page,
        rowsPerPage,
        sort,
      })
    )
      .then(() => {
        setIsLoading(false);
        setIsErrored(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsErrored(true);
      });
  };

  const fetchPageOfJobsCount = () => {
    setJobsCount(0);
    setIsJobsCountLoading(true);
    setJobCountError(false);
    dispatch(operations.fetchPageOfJobsCount(editBatchId))
      .then((count) => {
        setIsJobsCountLoading(false);
        setJobsCount(count);
      })
      .catch(() => {
        setIsJobsCountLoading(false);
        setJobCountError(true);
      });
  };

  const fieldsToWatch = [selectedFilterItems, rowsPerPage, sort];

  useEffect(() => {
    setPage(0);
  }, fieldsToWatch);

  useDeepCompareEffect(() => {
    fetchPageOfJobs();
    fetchPageOfJobsCount();
  }, [...fieldsToWatch, page, filterModes]);

  useDeepCompareEffect(() => {
    dispatch(setBatchJobs([]));
    dispatch(setIsSelectAllPages(false));
    dispatch(setIsSelectAllPagesLoading(false));
    dispatch(setBatchId(null));
    dispatch(setIncludesAutoStaffedJob(false));
    dispatch(setIncludedSourceSystems([]));
    dispatch(setSelectAllPageCount(0));
    dispatch(abort('fetchSelectAllPagesCount'));
  }, [otherSelectedFilterItems, filterModes]);

  useEffect(() => {
    setRows(
      pageOfJobs.map((job) => ({
        id: job.id,
        projectId: `${job.external_project_identifier} - ${job.name}`,
        assignmentId: job,
        associate: job,
        status_central: job,
        date: job,
        time: job,
        duration: convertMinsToHrsMins(job.estimated_time, false, true),
        location: job,
        storeLocationTimezone: job.store_location_timezone,
      }))
    );
  }, [pageOfJobs]);

  if (isErrored && !isLoading) {
    return (
      <DataGridErrorView
        cssOverride={{
          marginRight: `-${filterDrawerWidth}px`,
        }}
      />
    );
  }

  const onSelectAllClick = () => {
    dispatch(
      operations.fetchSelectAllPagesCount({
        hasFilters,
        editBatchId,
        rows,
        onSelectAllClick,
      })
    );
  };

  const onDeselectAllClick = () => {
    dispatch(setBatchId(null));
    dispatch(setSelectAllPageCount(0));
    dispatch(setIsSelectAllPages(false));
    dispatch(setBatchJobs([]));
    dispatch(setIncludesAutoStaffedJob(false));
    dispatch(setIncludedSourceSystems([]));
  };

  const columns = [
    {
      field: 'projectId',
      headerName: 'PROJECT ID & NAME',
      minWidth: 300,
      flex: 1,
      sortable: false,
    },
    {
      field: 'assignmentId',
      headerName: 'ASSIGNMENT ID',
      minWidth: 120,
      flex: 1,
      renderCell: ({ value: job }) => (
        <JobID
          jobId={job.id}
          externalIdentifier={job.external_identifier}
          advantageSourceSystemName={job.advantage_source_system_name}
          dataDogActionName="Staffing Work Assignment Id Link"
        />
      ),
      sortable: false,
    },
    {
      field: 'associate',
      headerName: 'ASSOCIATE',
      minWidth: 275,
      flex: 1,
      cellClassName: (params) => determineCellClassName(params),
      renderCell: ({ value: job }) => (
        <Associate job={job} getUpdatedJob={getUpdatedJob} />
      ),
    },
    {
      field: 'status_central',
      headerName: 'STATUS',
      minWidth: 110,
      flex: 1,
      renderCell: ({ value: job }) => (
        <JobStatus
          jobId={job.id}
          status={job.status_central}
          statusAttributes={job.status_attributes}
          confirmationStatus={job.confirmation_status}
        />
      ),
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'DATE',
      minWidth: 176,
      flex: 1,
      cellClassName: (params) => determineCellClassName(params),
      renderCell: ({ value: job }) => (
        <JobDateInfo
          id={job.id}
          status={job.status_central}
          startDate={job.job_start_after}
          storeTimezone={job.store_location_timezone}
          createdJobStartAfter={job.created_job_start_after}
          createdJobFinishBefore={job.created_job_finish_before}
          advantageSourceSystemName={job.advantage_source_system_name}
          externalIdentifier={job.external_identifier}
          getUpdatedJob={getUpdatedJob}
          job={job}
        />
      ),
    },
    {
      field: 'time',
      headerName: 'TIME',
      minWidth: 210,
      flex: 1,
      cellClassName: (params) => determineCellClassName(params),
      renderCell: ({ value: job }) => (
        <JobTimeInfo
          id={job.id}
          status={job.status_central}
          startDate={job.job_start_after}
          endDate={job.job_finish_before}
          eta={job.estimated_time}
          storeTimezone={job.store_location_timezone}
          createdJobStartAfter={job.created_job_start_after}
          createdJobFinishBefore={job.created_job_finish_before}
          advantageSourceSystemName={job.advantage_source_system_name}
          externalIdentifier={job.external_identifier}
          getUpdatedJob={getUpdatedJob}
          job={job}
        />
      ),
      sortable: false,
    },
    {
      field: 'location',
      headerName: 'LOCATION',
      minWidth: 220,
      flex: 1,
      renderCell: ({ value: job }) => (
        <JobAddress
          id={job.store_location_id}
          name={job.banner_name}
          address={job.store_location_address.address}
          details={`${job.store_location_address.city}, ${job.store_location_address.state}, ${job.store_location_address.postal_code}`}
          selfIdentity={job.store_location_self_identity}
        />
      ),
    },
  ];

  const sortOptions = {
    associate: ['user__first_name', 'user__last_name'],
    date: ['job_start_after'],
    location: [
      'store_location__store_chain__name',
      'store_location__profile__primary_self_identity',
    ],
  };

  return (
    <StyledDataGrid
      isOpen={isOpen}
      hasFilters={hasFilters}
      apiRef={gridApiRef}
      rows={rows}
      columns={columns}
      checkboxSelection={isBatchMode || isAutoMode}
      getRowHeight={() => 'auto'}
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      disableColumnResize
      sortingMode="server"
      onSortModelChange={(gridSortModel) => {
        if (gridSortModel.length > 0) {
          setSort(
            concatenateAndPrepend(
              sortMapping[gridSortModel[0].sort],
              sortOptions[gridSortModel[0].field],
              DEFAULT_SORT_ORDERING
            )
          );
        } else {
          setSort(DEFAULT_SORT_ORDERING.join(','));
        }
      }}
      rowSelectionModel={
        isRowSelectionEnabled ? batchJobs.map((batchJob) => batchJob.id) : []
      }
      onRowSelectionModelChange={(jobIds) => {
        const jobs = removeDuplicates([...pageOfJobs, ...batchJobs]);
        const filteredJobs = filterJobs(jobs, jobIds);
        dispatch(
          setIncludesAutoStaffedJob(
            filteredJobs.some((job) =>
              job.status_attributes.includes('auto_staffed')
            )
          )
        );
        dispatch(setBatchJobs(filteredJobs));
      }}
      keepNonExistentRowsSelected
      paginationMode="server"
      rowCount={rows.length}
      loading={isLoading}
      localeText={{ noRowsLabel: 'No projects to display' }}
      slots={{
        // eslint-disable-next-line react/no-unstable-nested-components
        baseCheckbox: React.forwardRef((props, ref) => (
          <Checkbox
            {...props}
            ref={ref}
            checked={isSelectAllPages ? true : props.checked}
            disabled={isSelectAllPages || pageOfJobs.length === 0}
            onChange={(event) => {
              const {
                target: { ariaLabel },
              } = event;
              if (ariaLabel === 'Select all rows') {
                dispatch(
                  setBatch(getBatchDialog(rows.length, onSelectAllClick))
                );
              }
              props.onChange(event);
            }}
          />
        )),
        // eslint-disable-next-line react/no-unstable-nested-components
        row: (props) => (
          <GridRow
            {...props}
            selected={isSelectAllPages ? true : props.selected}
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        footer: () => {
          const footerCount = determineFooterCount(
            batchJobs,
            isSelectAllPagesLoading,
            selectAllPagesCount
          );
          return (
            <>
              <Divider />
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '52px',
                }}
              >
                <Box
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginLeft: '16px',
                  }}
                  id="selected_row_area"
                >
                  {isRowSelectionEnabled && (
                    <>
                      {isSelectAllPagesLoading && (
                        <CircularProgress size="40px" />
                      )}
                      {footerCount && (
                        <Typography variant="body2" id="batch_row_count">
                          {footerCount}
                        </Typography>
                      )}
                    </>
                  )}
                  {batchId && (
                    <Button
                      id="deselect_all_jobs"
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        onDeselectAllClick();
                      }}
                    >
                      DESELECT ALL
                    </Button>
                  )}
                </Box>
                <TablePagination
                  as="div"
                  page={page}
                  count={
                    !isJobsCountLoading && !jobCountError ? jobsCount : 1000000
                  }
                  onPageChange={(event, value) => {
                    setPage(value);
                  }}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => {
                    setPage(0);
                    onRowsPerPageChange(event.target.value);
                  }}
                  rowsPerPageOptions={rowsPerPageOptions}
                  labelDisplayedRows={({ from, to, count }) =>
                    determineLabelDisplayRows(
                      from || 1,
                      to || rowsPerPage,
                      count,
                      isJobsCountLoading,
                      jobCountError
                    )
                  }
                />
              </Box>
            </>
          );
        },
      }}
    />
  );
}

export default JobTable;
