import styled from '@emotion/styled/macro';
import { Typography, TablePagination } from '@mui/material';
import { ReactComponent as Empty } from '../../assets/empty-states/empty.svg';
import { defaultColor } from '../../styles/theme';

export const AssignOrViewAssociateWrapper = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  top: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
`;

export const StyledAssociates = styled.div`
  padding-bottom: 54px;
`;

export const AssociateWrapper = styled.div`
  position: relative;
  background-color: ${defaultColor};
`;

export const ErrorCloseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 26px 0;
`;

export const NotFoundItemSecondary = styled(Typography)`
  width: 275px;
  margin: 0 auto;
  text-align: center;
  padding-top: 4px;
`;

export const StyledEmpty = styled(Empty)`
  margin: 16px;
`;

export const SuggestAssociatesPagination = styled(TablePagination)`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    overflow: hidden;
    height: 54px;
  }
`;

export const AssociateInfo = styled(Typography)`
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 100%;
  margin-bottom: 4px;
`;

export const AssociateInfoLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 100%;
`;
