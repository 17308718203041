import React, { useEffect, useState } from 'react';
import { useParams, Outlet, useLocation } from 'react-router-dom';

import { Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Breadcrumb from 'components/BreadCrumb/BreadCrumb';
import PageLayout from 'components/PageLayout/PageLayout';
import { getAssociate, getAssociateWorkPreferences } from 'services/user';
import { determineInitials } from 'components/Menu/utils';

import {
  AssociateDetailsWrapper,
  AssociateDetailsInfo,
  ErrorWrapper,
} from '../style';
import SecondaryNavigation from './SecondaryNavigation';
import { SkeletonAssociateDetailsInfo } from './SkeletonAssociateDetailsInfo';

function AssociateDetails() {
  const params = useParams();
  const { pathname } = useLocation();
  const { palette } = useTheme();
  const [associate, setAssociate] = useState(null);

  const [associateWorkPreferences, setAssociateWorkPreferences] =
    useState(null);
  const [detailsErrorMessage, setDetailsErrorMessage] = useState(null);
  const [preferencesErrorMessage, setPreferencesErrorMessage] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const pathnameSections = pathname
    .split('/')
    .filter((pathnameSection) => pathnameSection);
  const breadcrumbLinks = [
    { linkText: 'Associate List', href: `/${pathnameSections[0]}/` },
  ];

  const loadAssociate = (id) => {
    setDetailsLoading(true);

    getAssociate({ id })
      .then((associates) => {
        setAssociate(associates[0]);
        setDetailsLoading(false);
      })
      .catch((error) => {
        setDetailsErrorMessage(error.message);
        setDetailsLoading(false);
      });
  };

  const loadAssociateWorkPreferences = (id) => {
    setPreferencesLoading(true);

    getAssociateWorkPreferences(id)
      .then((associatesWorkPreferences) => {
        setAssociateWorkPreferences(associatesWorkPreferences);

        setPreferencesLoading(false);
      })
      .catch((error) => {
        setPreferencesErrorMessage(error.message);
        setPreferencesLoading(false);
      });
  };

  useEffect(() => {
    loadAssociate(params.associateId);
    loadAssociateWorkPreferences(params.associateId);
  }, [params.associateId]);

  if (detailsErrorMessage) {
    return (
      <PageLayout>
        <ErrorWrapper
          id="associate-details-error"
          css={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>{detailsErrorMessage}</Typography>
        </ErrorWrapper>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      cssClassName={{ backgroundColor: palette.backgroundColor.secondary }}
    >
      <AssociateDetailsWrapper>
        <Breadcrumb
          breadcrumbLinks={breadcrumbLinks}
          detailsText="Associate Details"
        />
        {detailsLoading ? (
          <SkeletonAssociateDetailsInfo />
        ) : (
          <AssociateDetailsInfo>
            {!associate ? (
              <Typography>No information to display</Typography>
            ) : (
              <>
                <Avatar
                  css={{
                    marginRight: '16px',
                    fontSize: '20px',
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {determineInitials(associate.full_name)}
                </Avatar>
                <div>
                  <Typography
                    id="associate-full_name"
                    variant="h5"
                    fontWeight="700"
                    color={palette.text.primary}
                  >
                    {associate.full_name}
                  </Typography>
                  <Typography id="associate-title" variant="body1">
                    {associate.title}
                  </Typography>
                  <Typography
                    id="associate-external_id"
                    variant="body2"
                    color={palette.text.secondary}
                  >
                    {associate.external_id}
                  </Typography>
                </div>
              </>
            )}
          </AssociateDetailsInfo>
        )}
      </AssociateDetailsWrapper>

      <SecondaryNavigation associateId={params.associateId} />

      <Outlet
        context={{
          associateContext: {
            associate,
            isLoading: detailsLoading,
          },
          associateWorkPreferencesContext: {
            associateTimeZone: associate?.profile?.timezone,
            associateWorkPreferences,
            isLoading: preferencesLoading,
            error: preferencesErrorMessage,
          },
        }}
      />
    </PageLayout>
  );
}

export default AssociateDetails;
