import React from 'react';

import Link from '@mui/material/Link';
import ErrorPage from '../ErrorPage/ErrorPage';
import { ReactComponent as ErrorIcon } from '../../assets/empty-states/5xx-error.svg';

function NotFound404() {
  return (
    <ErrorPage icon={ErrorIcon}>
      The page you are trying to open doesn&#39;t seem to exist. Please try
      opening another page from the main menu on the left or if you believe this
      to be a mistake, please{' '}
      <Link
        id="submit-bug-report"
        href="https://advantageprod.service-now.com/esc?id=sc_cat_item&sys_id=6714d53c93d48690a0a6b80e1dba108f"
        target="_blank"
        rel="noopener noreferrer"
      >
        submit a bug report
      </Link>
      .
    </ErrorPage>
  );
}

export default NotFound404;
