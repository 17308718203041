import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import AssociateDetails from 'components/AssociateWorkPreferences/components/AssociateDetails';
import AssociateContact from 'components/AssociateWorkPreferences/components/AssociateContact';
import AssociateWorkPreferences from 'components/AssociateWorkPreferences/AssociateWorkPreferences';
import BatchDetailsContainer from 'components/BatchDetails/BatchDetails';
import BatchDetailsAssignmentsContainer from 'components/BatchDetailsAssignments/BatchDetailsAssignments';
import store from 'store2';
import { SnackbarProvider } from 'notistack';
import { useDispatch } from 'react-redux';
import HomeContainer from './components/Home/Home';
import Associates from './components/Associates/Associates';
import NotFound404 from './components/NotFound404/NotFound404';
import {
  DATADOG_LOGS,
  DATADOG_RUM,
  ENV,
  ENV_E2ETESTS,
  ENV_E2ETESTS_CI,
  ENV_LOCAL,
  OKTA,
  VIEWS,
} from './core/config';
import GAWrapper from './components/GA/GA';

import AuthorizationWrapper from './core/router/AuthorizationWrapper';
import Logout from './components/Logout/Logout';
import Loading from './core/router/Loading';
import { RequiredAuth } from './core/router/SecureRoute';
import auth from './services/auth';
import { getVersion } from './services/health';
import { theme } from './styles/theme/index';
import FallBack from './components/FallBack/FallBack';
import Health from './components/Health/Health';
import GA from './services/ga';
import Error from './components/Error/Error';
import HelpContainer from './components/Help/Help';
import Toast from './components/Toast/Toast';
import { VARIANTS } from './constants';
import { setGroups } from './state/ducks/access/actions';

export default function App(props) {
  const dispatch = useDispatch();
  const oneHour = 60 * 60 * 1000;
  const currentUser = auth.getCurrentUser();

  useEffect(() => {
    if (currentUser?.groups) {
      dispatch(setGroups(currentUser.groups));
    }
  }, [currentUser]);

  useEffect(() => {
    if (![ENV_LOCAL, ENV_E2ETESTS, ENV_E2ETESTS_CI].includes(ENV)) {
      datadogRum.init(DATADOG_RUM);
      datadogRum.setUser(currentUser);
      datadogLogs.init(DATADOG_LOGS);
      GA.load();
    }

    const persistAuthorizationKey = 'persist:authorization';
    if (store.get(persistAuthorizationKey)) {
      store.remove(persistAuthorizationKey);
    }
    const isAuthorizedKey = 'isAuthorized';
    if (store.get(isAuthorizedKey)) {
      store.remove(isAuthorizedKey);
    }

    const interval = setInterval(async () => {
      await getVersion();
    }, oneHour);

    return () => clearInterval(interval);
  }, []);

  const oktaAuth = new OktaAuth({
    issuer: OKTA[process.env.REACT_APP_ENV_BUILD].issuer,
    clientId: OKTA[process.env.REACT_APP_ENV_BUILD].clientId,
    scopes: ['openid', 'profile', 'email'],
    redirectUri: `${window.location.origin}/login/callback`,
    pkce: true,
  });
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    try {
      const originalRelativeUri = toRelativeUrl(
        originalUri || '/',
        window.location.origin
      );
      await auth.processOktaLogin();
      navigate(originalRelativeUri);
    } catch (error) {
      if (error.response.status === 500) {
        navigate(VIEWS.private.error);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={FallBack}
        onError={(error, info) => {
          datadogLogs.logger.error(
            'ErrorBoundary was invoked',
            {
              info,
            },
            error
          );
        }}
      >
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              autoHideDuration={8000}
              disableWindowBlurListener
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              Components={Object.keys(VARIANTS).reduce(
                (accumulator, key) => ({
                  ...accumulator,
                  [key]: Toast,
                }),
                {}
              )}
            >
              <GAWrapper>
                <Routes>
                  <Route
                    path={VIEWS.public.loginCallback}
                    element={<LoginCallback loadingElement={<Loading />} />}
                  />
                  <Route path={VIEWS.private.home} element={<RequiredAuth />}>
                    <Route
                      path={VIEWS.private.home}
                      element={
                        <AuthorizationWrapper
                          component={HomeContainer}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path={VIEWS.private.associate}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path={VIEWS.private.associate}
                      element={
                        <AuthorizationWrapper
                          component={AssociateDetails}
                          {...props}
                        />
                      }
                    >
                      <Route
                        path={VIEWS.private.associateContact}
                        element={
                          <AuthorizationWrapper
                            component={AssociateContact}
                            {...props}
                          />
                        }
                      />
                      <Route
                        path={VIEWS.private.associateWorkPreferences}
                        element={
                          <AuthorizationWrapper
                            component={AssociateWorkPreferences}
                            {...props}
                          />
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path={VIEWS.private.associates}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path={VIEWS.private.associates}
                      element={
                        <AuthorizationWrapper
                          component={Associates}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route path={VIEWS.private.help} element={<RequiredAuth />}>
                    <Route
                      path={VIEWS.private.help}
                      element={
                        <AuthorizationWrapper
                          component={HelpContainer}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path={VIEWS.private.batchDetails}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path={VIEWS.private.batchDetails}
                      element={
                        <AuthorizationWrapper
                          component={BatchDetailsContainer}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route
                    path={VIEWS.private.batchDetailsAssignments}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path={VIEWS.private.batchDetailsAssignments}
                      element={
                        <AuthorizationWrapper
                          component={BatchDetailsAssignmentsContainer}
                          {...props}
                        />
                      }
                    />
                  </Route>
                  <Route path={VIEWS.private.error} element={<RequiredAuth />}>
                    <Route
                      path={VIEWS.private.error}
                      element={
                        <AuthorizationWrapper component={Error} {...props} />
                      }
                    />
                  </Route>
                  <Route path={VIEWS.public.logout} element={<Logout />} />
                  <Route path={VIEWS.public.health} element={<Health />} />
                  <Route
                    path={VIEWS.private.catchAll}
                    element={<RequiredAuth />}
                  >
                    <Route
                      path={VIEWS.private.catchAll}
                      element={
                        <AuthorizationWrapper
                          component={NotFound404}
                          {...props}
                        />
                      }
                    />
                  </Route>
                </Routes>
              </GAWrapper>
            </SnackbarProvider>
          </LocalizationProvider>
        </Security>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
