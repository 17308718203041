import React from 'react';
import PageLayout from '../PageLayout/PageLayout';
import Help from './components/Help';

function HelpContainer() {
  return (
    <PageLayout>
      <Help />
    </PageLayout>
  );
}

export default HelpContainer;
