import React from 'react';

import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import ErrorPage from '../ErrorPage/ErrorPage';
import { removeAllFromCacheThatStartWith } from '../../services/cache';
import { ReactComponent as ErrorIcon } from '../../assets/empty-states/5xx-error.svg';
import { updateSelectedFilterItems } from '../../state/ducks/filters/actions';
import { DEFAULT_SELECTED_FILTERS } from '../Filters/Filters';

function FallBack() {
  const dispatch = useDispatch();
  return (
    <ErrorPage icon={ErrorIcon}>
      <Box>
        Something unexpected has happened and an error has been logged. To
        continue, use the Reset button below to clear the site cache.
      </Box>
      <Button
        id="fallback-reset"
        variant="outlined"
        sx={{
          margin: '6px',
        }}
        onClick={() => {
          removeAllFromCacheThatStartWith(['boostRetail']);
          Promise.resolve(
            dispatch(updateSelectedFilterItems(DEFAULT_SELECTED_FILTERS))
          ).then(() => {
            window.location.reload();
          });
        }}
      >
        Reset
      </Button>
      <Box>
        If the problem continues, please call the Boost Helpdesk at
        1-888-900-4275.
      </Box>
    </ErrorPage>
  );
}

export default FallBack;
