import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssociateDetails from '../AssociateDetails/AssociateDetails';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';
import { DEFAULT_VIEW_ASSOCIATE } from '../../state/ducks/viewAssociate/constants';
import { setSuggestAssociateJob } from '../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../state/ducks/suggestAssociate/constants';
import SuggestAssociates from '../SuggestAssociates/SuggestAssociates';
import { associateDrawerWidth } from '../SuggestAssociates/constants';

function AssociateDrawer({ setSelectedAssociate, gridApiRef }) {
  const selectedAssociate = useSelector((state) => state.viewAssociate);
  const suggestAssociateJob = useSelector(
    (state) => state.suggestAssociate.job
  );
  const openSuggestAssociates = !!suggestAssociateJob.id;
  const openAssociateDetails = !!selectedAssociate.user_id;
  const open = openSuggestAssociates || openAssociateDetails;
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
    dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
  };
  const theme = useTheme();

  function determineComponents() {
    if (openSuggestAssociates) {
      return (
        <Box css={{ display: 'flex', height: '100%' }}>
          <Slide
            direction="left"
            in={openAssociateDetails}
            mountOnEnter
            unmountOnExit
            timeout={115}
          >
            <Box css={{ display: 'flex' }}>
              <AssociateDetails
                setSelectedAssociate={setSelectedAssociate}
                gridApiRef={gridApiRef}
              />
              <Divider orientation="vertical" />
            </Box>
          </Slide>
          <SuggestAssociates
            setSelectedAssociate={setSelectedAssociate}
            gridApiRef={gridApiRef}
          />
        </Box>
      );
    }
    if (openAssociateDetails) {
      return (
        <AssociateDetails
          setSelectedAssociate={setSelectedAssociate}
          gridApiRef={gridApiRef}
        />
      );
    }
    return <Box css={{ width: associateDrawerWidth }} />;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      id="associate-drawer"
      onClose={onClose}
      css={{ zIndex: theme.zIndex.drawer + 2 }}
    >
      {determineComponents()}
    </Drawer>
  );
}

export default AssociateDrawer;
