import React from 'react';
import { useTheme } from '@mui/material/styles';
import PageLayout from 'components/PageLayout/PageLayout';
import AssociateDrawer from 'components/AssociateDrawer/AssociateDrawer';
import BatchDetails from './components/BatchDetails';

function BatchDetailsContainer() {
  const theme = useTheme();
  return (
    <PageLayout
      cssClassName={{
        backgroundColor: theme.palette.backgroundColor.primary,
      }}
    >
      <BatchDetails />
      <AssociateDrawer />
    </PageLayout>
  );
}

export default BatchDetailsContainer;
