import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { VIEWS } from '../config';
import Unauthorized from '../../components/Unauthorized/Unauthorized';

function AuthorizationWrapper({ component: Component, ...rest }) {
  const {
    authState: { isAuthenticated },
  } = useOktaAuth();
  const isAuthorized = useSelector((state) => state.access.isAuthorized);

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: VIEWS.public.logout,
          state: { from: rest.location },
        }}
      />
    );
  }

  if (isAuthorized === false) {
    return <Unauthorized />;
  }

  return <Component {...rest} />;
}

export default AuthorizationWrapper;
