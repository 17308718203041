import React from 'react';
import { Box } from '@mui/material';

import { format, utcToZonedTime } from 'date-fns-tz';
import {
  displayAvailability,
  displayCommuteMiles,
  displayMaxDailyHours,
} from 'components/AssociateWorkPreferences/utils';
import PreferenceItem from './PreferenceItem';

function EventPreferences({ preferences, operation, associateTimeZone }) {
  return (
    <Box
      id="event-preference-event-log"
      css={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
    >
      {preferences.effective_date && (
        <PreferenceItem
          title="Effective Date"
          newAvailability={preferences.effective_date.new}
          oldAvailability={preferences.effective_date.old}
          operation={operation}
          displayFunction={(date) =>
            format(
              utcToZonedTime(new Date(date), associateTimeZone),
              'MM/dd/yyyy'
            )
          }
        />
      )}
      {preferences.my_home_store_only && (
        <PreferenceItem
          title="Home Store Only"
          newAvailability={preferences.my_home_store_only.new}
          oldAvailability={preferences.my_home_store_only.old}
          operation={operation}
          displayFunction={displayAvailability}
          eventPreferenceId="home_store_only"
          titleId="home_store_only_title"
        />
      )}
      {preferences.maximum_commute_miles && (
        <PreferenceItem
          title="Willing to drive"
          newAvailability={preferences.maximum_commute_miles.new}
          oldAvailability={preferences.maximum_commute_miles.old}
          operation={operation}
          displayFunction={displayCommuteMiles}
        />
      )}

      {preferences.allow_overnight_stay && (
        <PreferenceItem
          title="Travel and stay overnight"
          newAvailability={preferences.allow_overnight_stay.new}
          oldAvailability={preferences.allow_overnight_stay.old}
          operation={operation}
          displayFunction={displayAvailability}
        />
      )}
      {preferences.preferred_weekly_hours && (
        <PreferenceItem
          title="Preferred hours a week"
          newAvailability={preferences.preferred_weekly_hours.new}
          oldAvailability={preferences.preferred_weekly_hours.old}
          operation={operation}
          displayFunction={(hours) => hours || '0'}
        />
      )}
      {preferences.preferred_daily_hours && (
        <PreferenceItem
          title="Maximum hours per day"
          newAvailability={preferences.preferred_daily_hours.new}
          oldAvailability={preferences.preferred_daily_hours.old}
          operation={operation}
          displayFunction={displayMaxDailyHours}
        />
      )}
      {preferences.my_division_only && (
        <PreferenceItem
          title="Division"
          newAvailability={preferences.my_division_only.new}
          oldAvailability={preferences.my_division_only.old}
          operation={operation}
          displayFunction={displayAvailability}
        />
      )}
    </Box>
  );
}

export default EventPreferences;
