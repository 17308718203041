import React from 'react';

import { AssociateInfoWrapper } from './style';
import AssociateActivity from './components/AssociateActivity';
import WorkPreferences from './components/WorkPreferences';

function AssociateWorkPreferences() {
  return (
    <AssociateInfoWrapper className="associate-work-preferences">
      <WorkPreferences />
      <AssociateActivity />
    </AssociateInfoWrapper>
  );
}

export default AssociateWorkPreferences;
