import React from 'react';
import { Divider, Typography } from '@mui/material';
import { ErrorWrapper } from 'components/ErrorPage/style';
import { useTheme } from '@mui/material/styles';

function DataGridErrorView({ cssOverride }) {
  const theme = useTheme();
  return (
    <ErrorWrapper
      cssOverride={{
        borderTop: `1px solid ${theme.palette.divider}`,
        ...cssOverride,
      }}
    >
      <Divider />
      <Typography id="error-message" variant="h6">
        Whoopsie Daisy!
      </Typography>
      <Typography id="error-description" variant="body1">
        Something that should never happen seems to have happened and we&apos;re
        terribly sorry.
      </Typography>
    </ErrorWrapper>
  );
}

export default DataGridErrorView;
