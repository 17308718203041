import * as React from 'react';

import { Typography } from '@mui/material';
import { ErrorWrapper } from './style';
import PageLayout from '../PageLayout/PageLayout';

function ErrorPage({ icon, children }) {
  return (
    <PageLayout>
      <ErrorWrapper icon={icon}>
        <Typography variant="body1" sx={{ maxWidth: 400, textAlign: 'center' }}>
          {children}
        </Typography>
      </ErrorWrapper>
    </PageLayout>
  );
}

export default ErrorPage;
