import React from 'react';

import {
  AssociateWorkPreferencesRowWrapper,
  AvailabilityTypography,
} from './style';

function AssociateAvailabilityInfoRow({ label, children, id }) {
  return (
    <AssociateWorkPreferencesRowWrapper id={id}>
      <AvailabilityTypography variant="body1">{label}</AvailabilityTypography>
      {children}
    </AssociateWorkPreferencesRowWrapper>
  );
}

export default AssociateAvailabilityInfoRow;
