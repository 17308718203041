import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchFeatureFlags } from 'state/ducks/featureFlags/operations';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import FilterDrawer, { DEFAULT_SELECTED_FILTERS } from '../Filters/Filters';
import PageLayout from '../PageLayout/PageLayout';
import CallToActionBar from '../CallToActionBar/CallToActionBar';
import BatchActionBar from '../BatchActionBar/BatchActionBar';
import JobTable from '../JobTable/JobTable';
import Drawers from './components/Drawers';
import { setStaffingWorkRowsPerPage } from '../../state/ducks/staffingWorkPage/actions';

function HomeContainer() {
  const gridApiRef = useGridApiRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeatureFlags(['showSingleModeInlineEdit']));
  }, []);

  return (
    <PageLayout hasFilters>
      <BatchActionBar />
      <CallToActionBar />
      <Box
        css={{
          display: 'flex',
          overflow: 'hidden',
          height: '100%',
          width: '100%',
        }}
      >
        <JobTable
          pageName="staffingWork"
          onRowsPerPageChange={(value) => {
            dispatch(setStaffingWorkRowsPerPage(value));
          }}
          gridApiRef={gridApiRef}
          hasFilters
        />
        <Drawers gridApiRef={gridApiRef} />
        <FilterDrawer defaultFilters={DEFAULT_SELECTED_FILTERS} />
      </Box>
    </PageLayout>
  );
}

export default HomeContainer;
