export const ENV_LOCAL = 'local';
export const ENV_DEV = 'dev';
export const ENV_QA = 'qa';
export const ENV_UAT = 'uat';
export const ENV_PROD = 'prod';
export const ENV_E2ETESTS = 'end2EndTests';
export const ENV_E2ETESTS_CI = 'end2EndTestsCi';
export const ENV_SHIPYARD_EPHEMERAL = 'shipyardEphemeral';
export const ENV = process.env.REACT_APP_ENV_BUILD;
export const EXPORT_IS_DEV = (a, b) => (ENV === ENV_DEV ? a : b);

export const JWT_PAYLOAD_LOCAL_STORAGE_KEY =
  'jyve.k3Llemm0051-bn8f8942kjsdnm--38vnnjkl';
export const CSRF_TOKEN_COOKIE_KEY = 'csrftoken';
export const USER_TIMEZONE_KEY = 'timezone';

export const GA_ID = 'G-6YG9XPDZ9G';

export const API_DOMAIN = {
  [ENV_LOCAL]: 'https://api.dev.boostretail.com/v2/',
  [ENV_DEV]: 'https://api.dev.boostretail.com/v2/',
  [ENV_QA]: 'https://api.qa.boostretail.com/v2/',
  [ENV_UAT]: 'https://api.uat.boostretail.com/v2/',
  [ENV_PROD]: 'https://api.boostretail.com/v2/',
  [ENV_E2ETESTS]: 'https://django.local.boostretail.com:8001/api/v2/',
  [ENV_E2ETESTS_CI]: 'http://localhost:8000/api/v2/',
};

export const CLOUDFLARE_API_DOMAIN = {
  [ENV_DEV]: 'https://api.dev.boostretail.asmedge.net/v2/',
  [ENV_QA]: 'https://api.qa.boostretail.asmedge.net/v2/',
  [ENV_UAT]: 'https://api.uat.boostretail.asmedge.net/v2/',
  [ENV_PROD]: 'https://api.boostretail.asmedge.net/v2/',
};

export const getApiDomain = (env) => {
  const isCloudFlare = window.location.href.indexOf('asmedge.net') > -1;

  if (isCloudFlare) {
    return CLOUDFLARE_API_DOMAIN[env];
  }
  if (env === ENV_SHIPYARD_EPHEMERAL) {
    return `${window.location.origin}/api/v2/`;
  }

  return API_DOMAIN[env];
};

const oktaNonProd = {
  clientId: '0oa1k0pd0bkYBUmPN0h8',
  issuer: 'https://advantagesolutions.oktapreview.com/',
};

const oktaProd = {
  clientId: '0oarm0tlzsaUrZlMf1t7',
  issuer: 'https://advantagesolutions.okta.com/',
};

const oktaE2ETests = {
  clientId: '0oa95jk5l3WVUM69E5d7',
  issuer: 'https://dev-83145566.okta.com/',
};

export const OKTA = {
  [ENV_LOCAL]: oktaNonProd,
  [ENV_DEV]: oktaNonProd,
  [ENV_QA]: oktaNonProd,
  [ENV_UAT]: oktaNonProd,
  [ENV_PROD]: oktaProd,
  [ENV_E2ETESTS]: oktaE2ETests,
  [ENV_E2ETESTS_CI]: oktaE2ETests,
  [ENV_SHIPYARD_EPHEMERAL]: oktaNonProd,
};

const botPattern =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|pingdom)';
const regex = new RegExp(botPattern, 'i');
const conditionalSampleRate = regex.test(navigator.userAgent) ? 0 : 100;

const clientToken = 'pub1b6c799f603a3fda6f6acb8c13f83027';
const site = 'us3.datadoghq.com';
const service = 'boost-central-app';
const version = process.env.REACT_APP_GIT_SHA;
export const DATADOG_RUM = {
  applicationId: '67291f11-a85d-46a3-80da-a03b5db8870e',
  beforeSend: (event) => {
    const {
      innerHeight,
      innerWidth,
      screen: { height, width },
    } = window;
    // eslint-disable-next-line no-param-reassign
    event.context.screen = {
      height,
      width,
      innerHeight,
      innerWidth,
    };
    return true;
  },
  clientToken,
  site,
  service,
  env: ENV,
  version,
  sessionSampleRate: conditionalSampleRate,
  sessionReplaySampleRate: 100,
  premiumSampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [getApiDomain(ENV)],
};

export const DATADOG_LOGS = {
  clientToken,
  site,
  service,
  env: ENV,
  version,
  forwardConsoleLogs: 'all',
};

export const URL = {
  login: '/auth/okta-handshake-login/?jwt_auth=true',
  logout: '/jwt-token/delete/',
  tokenRefresh: '/jwt-token/refresh/',
  jobs: '/jobs/central/',
  associates: '/users/',
  featureFlags: '/feature-flags/status/',
};

export const VIEWS = {
  private: {
    associate: '/associates/:associateId',
    associates: '/associates',
    associateContact: '/associates/:associateId/contact',
    associateWorkPreferences: '/associates/:associateId/work-preferences',
    batchDetails: '/batch-details/:batchId',
    batchDetailsAssignments: '/batch-details/:batchId/assignments',
    catchAll: '*',
    error: '/error',
    help: '/help',
    home: '/',
  },
  public: {
    loginCallback: '/login/callback',
    logout: '/logout',
    health: '/health',
  },
};

export const MUI_PRO_LICENSE_KEY =
  '5e78e48acee737eeb9286f83c54bc902Tz0xMDQyOTQsRT0xNzY1NzQyOTgzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=';
