/* eslint-disable camelcase */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

import {
  formatChangedDate,
  getAvailabilityStatus,
} from 'components/AssociateWorkPreferences/utils';
import { ActivityEventInfo, ActivityEventStatus } from './style';
import EventPreferences from './EventPreferences';

function AssociateWeeklyActivityEvent({ eventLog, associateTimeZone }) {
  const theme = useTheme();
  const { weekly_availability, created_at, operation } = eventLog;

  return (
    <Box className="weekly-activity-event">
      <ActivityEventInfo>
        <ActivityEventStatus>
          <Typography
            id="daily-weekly-changed-time"
            variant="body2"
            color={theme.palette.text.secondary}
          >
            {formatChangedDate(created_at, associateTimeZone)}
          </Typography>
          <Typography
            id="availability-change"
            variant="body2"
            color={theme.palette.text.secondary}
          >
            {getAvailabilityStatus(
              weekly_availability.actual_effective_date,
              associateTimeZone
            )}
          </Typography>
        </ActivityEventStatus>
      </ActivityEventInfo>

      <EventPreferences
        preferences={weekly_availability}
        operation={operation}
        associateTimeZone={associateTimeZone}
      />

      {operation === 'DELETE' && (
        <Box id="weekly-delete" css={{ display: 'flex', alignItems: 'center' }}>
          <CancelIcon color="error" css={{ width: '16px', height: '16px' }} />
          <Typography
            variant="subtitle3"
            css={{ color: '#D32F2F', marginLeft: '4px' }}
          >
            Removed
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default AssociateWeeklyActivityEvent;
