import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterListIcon from '@mui/icons-material/FilterList';

import Tabs from 'components/Header/components/Tabs';
import store from 'store2';
import { ReactComponent as BoostRetailLogo } from '../../assets/header/boost-retail-logo.svg';

import { determineNumberOfFilters } from '../Filters/utils';
import ClearFiltersCounter from '../Filters/components/ClearFiltersCounter';
import {
  DEFAULT_FILTER_MODES,
  DEFAULT_FILTER_QUERIES,
  RESET_SELECTED_FILTERS,
} from '../Filters/constants';
import { VIEWS } from '../../core/config';
import { Banner } from '../Banner/Banner';
import {
  setShowFilters,
  updateFilterModes,
  updateFilterQueries,
  updateSelectedFilterItems,
} from '../../state/ducks/filters/actions';
import { setShowMainMenu } from '../../state/ducks/core/actions';
import { FILTERS_CACHE_KEY } from '../../state/ducks/filters/constants';
import { mapFiltersForCache } from '../../state/ducks/filters/cache';

function Header({ hasFilters = false }) {
  const isMainMenuOpen = useSelector((state) => state.core.isMainMenuOpen);
  const dispatch = useDispatch();
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const numberOfActiveFilters = determineNumberOfFilters(selectedFilterItems);
  const areFiltersOpen = useSelector(
    (state) => state.filters.visibility.isOpen
  );
  const toggleMenu = () => {
    dispatch(setShowMainMenu(!isMainMenuOpen));
  };
  const toggleFiltersVisibility = () => {
    dispatch(setShowFilters(!areFiltersOpen));
  };
  const theme = useTheme();

  useEffect(() => {
    const handleBeforeUnload = () => {
      store.set(FILTERS_CACHE_KEY, mapFiltersForCache(selectedFilterItems));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selectedFilterItems]);

  return (
    <AppBar
      position="fixed"
      css={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Banner />
      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 24px',
        }}
      >
        <Box css={{ display: 'flex' }}>
          <IconButton
            id="main-navigational-menu-button"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            css={{
              marginRight: 1,
            }}
            onClick={() => {
              toggleMenu();
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            href={VIEWS.private.home}
            css={{ margin: 'auto 0' }}
            aria-label="Return to Boost Central homepage"
          >
            <BoostRetailLogo />
          </IconButton>
          <Tabs />
        </Box>
        <Box>
          {hasFilters && (
            <>
              {numberOfActiveFilters > 0 && (
                <ClearFiltersCounter
                  sectionKeys={['all']}
                  label="Applied Filters"
                  numberOfActiveFilters={numberOfActiveFilters}
                  onClear={() => {
                    dispatch(updateSelectedFilterItems(RESET_SELECTED_FILTERS));
                    dispatch(updateFilterQueries(DEFAULT_FILTER_QUERIES));
                    dispatch(updateFilterModes(DEFAULT_FILTER_MODES));
                  }}
                />
              )}
              <IconButton
                id="filter-drawer-toggle-button"
                size="large"
                edge="end"
                color="inherit"
                aria-label="filters"
                css={{ marginLeft: 'auto' }}
                onClick={() => toggleFiltersVisibility()}
              >
                {areFiltersOpen ? <FilterListOffIcon /> : <FilterListIcon />}
              </IconButton>
            </>
          )}
        </Box>
      </Box>
    </AppBar>
  );
}

export default Header;
