import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

function UnreadChangesSection({
  showUnreadChanges,
  setPage,
  setShowUnreadChanges,
}) {
  const theme = useTheme();
  return (
    <>
      <Divider />
      <Box
        css={{
          backgroundColor: theme.palette.backgroundColor.secondary,
          height: '46px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="unread-changes-checkbox"
              checked={showUnreadChanges}
              onChange={({ target: { checked } }) => {
                setPage(0);
                setShowUnreadChanges(checked);
              }}
              size="small"
            />
          }
          label="Unread Changes"
          css={{
            paddingLeft: '40px',
            color: theme.palette.text.primary,
          }}
        />
      </Box>
    </>
  );
}

export default UnreadChangesSection;
