/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Alert,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/Spinner/Spinner';
import * as eventLogsOperations from '../../../state/ducks/workPreferencesPage/operations';

import {
  ActivityPagination,
  AssociateActivityHeader,
  AssociateActivityList,
  AssociateActivityWrapper,
} from '../style';
import AssociateEvent from './AssociateEvent';
import { setRowsPerPage } from '../../../state/ducks/workPreferencesPage/actions';
import { rowsPerPageOptions } from '../../../constants';

function AssociateActivity() {
  const {
    associateWorkPreferencesContext: { associateTimeZone },
  } = useOutletContext();
  const params = useParams();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(null);
  const [page, setPage] = useState(0);
  const rowsPerPage = useSelector(
    (state) => state.workPreferencesPage.cache.rowsPerPage
  );
  const [unReadOnly, setUnreadOnly] = useState(true);
  const eventLogs = useSelector((state) => state.workPreferencesPage.eventLogs);

  const fetchEventLogs = () => {
    setIsLoading(true);
    dispatch(
      eventLogsOperations.fetchEventLogs(
        page,
        rowsPerPage,
        params.associateId,
        !unReadOnly
      )
    )
      .then(() => {
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch(() => {
        setIsErrored(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchEventLogs();
  }, [page, rowsPerPage, params.associateId, unReadOnly]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(event.target.value));
    setPage(0);
  };

  return (
    <AssociateActivityWrapper>
      <AssociateActivityHeader>
        <Typography variant="h6" css={{ color: theme.palette.text.primary }}>
          Activity
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              id="unread-only-checkbox"
              checked={unReadOnly}
              onChange={({ target: { checked } }) => {
                setUnreadOnly(checked);
                setPage(0);
              }}
              size="small"
            />
          }
          label="Unread Only"
          css={{
            paddingLeft: '40px',
            color: theme.palette.text.primary,
          }}
        />
      </AssociateActivityHeader>

      {isLoading && (
        <Box
          css={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingTop: '50px',
          }}
        >
          <Spinner height="60" width="60" color="rgba(0, 0, 0, 0.1)" />
        </Box>
      )}

      {isErrored && (
        <Box
          css={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
          id="associate-activity-error-message"
        >
          <Alert
            css={{ marginTop: '50px', width: '320px' }}
            variant="outlined"
            severity="error"
          >
            There was an error loading activity notices. Please try again later.
          </Alert>
        </Box>
      )}

      {!isLoading && !isErrored && (
        <>
          <AssociateActivityList id="associate-activity-list">
            {eventLogs.length === 0 ? (
              <Typography
                variant="body1"
                css={{
                  color: theme.palette.text.secondary,
                  margin: '48px auto',
                }}
                id="associate-activity-no-result"
              >
                No activity to display
              </Typography>
            ) : (
              eventLogs.map((eventLog) => (
                <AssociateEvent
                  key={eventLog.id}
                  eventLog={eventLog}
                  fetchEventLogs={fetchEventLogs}
                  associateId={params.associateId}
                  associateTimezone={associateTimeZone}
                />
              ))
            )}
          </AssociateActivityList>
          <ActivityPagination
            component="div"
            labelRowsPerPage="Items per page:"
            count={1000000000}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to }) => `${from}–${to}`}
          />
        </>
      )}
    </AssociateActivityWrapper>
  );
}

export default AssociateActivity;
