import React from 'react';
import { Box } from '@mui/material';
import {
  AssociateInfoWrapper,
  AssociateLeftColumn,
} from 'components/AssociateWorkPreferences/style';
import { useTheme } from '@mui/material/styles';
import AssociateContactInfoRow from './AssociateContactInfoRow';

function SkeletonAssociateContact() {
  const theme = useTheme();

  return (
    <AssociateInfoWrapper className="associate-contact">
      <AssociateLeftColumn>
        <AssociateContactInfoRow
          isLoading
          id="associate-email"
          label="Email"
          type="email"
        />
        <AssociateContactInfoRow
          isLoading
          id="associate-phone_number"
          label="Phone"
        />
        <AssociateContactInfoRow
          isLoading
          id="associate-address"
          label="Address"
        />
        <AssociateContactInfoRow
          id="associate-division_name"
          label="Team / Retailer"
          isLoading
        />
        <AssociateContactInfoRow
          id="associate-home_store_location"
          label="Home Store"
          isLoading
        />
      </AssociateLeftColumn>
      <Box
        css={{
          backgroundColor: theme.palette.backgroundColor.primary,
          width: '100%',
        }}
      />
    </AssociateInfoWrapper>
  );
}

export default SkeletonAssociateContact;
