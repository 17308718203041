import React, { useEffect, useState } from 'react';
import PageLayout from 'components/PageLayout/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { DEFAULT_ASSOCIATES_SORT_ORDERING } from 'state/ducks/associatesPage/constants';
import { AssociateHeaderWrapper } from './style';
import ManagerSelect from '../AssociateTable/components/ManagerSelect';
import AssociateSearch from '../AssociateTable/components/AssociateSearch';
import { fetchAssociates } from '../../state/ducks/associatesPage/operations';
import UnreadChangesSection from './UnreadChangesSection';
import AssociateTable from '../AssociateTable/AssociateTable';

function Associates() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = useSelector(
    (state) => state.associatesPage.cache.rowsPerPage
  );
  const [sort, setSort] = useState(DEFAULT_ASSOCIATES_SORT_ORDERING.join(','));
  const [showUnreadChanges, setShowUnreadChanges] = useState(true);
  const { id } = useSelector((state) => state.manager);
  const [associateIds, setAssociateIds] = useState('');

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(
        fetchAssociates(
          page,
          sort,
          rowsPerPage,
          associateIds,
          id,
          showUnreadChanges
        )
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsErrored(true);
          setIsLoading(false);
        });
    }
  }, [page, sort, rowsPerPage, associateIds, id, showUnreadChanges]);

  return (
    <PageLayout>
      <AssociateHeaderWrapper>
        <Typography variant="h4">Associate List</Typography>
        <ManagerSelect setPage={setPage} />
      </AssociateHeaderWrapper>
      <AssociateSearch setAssociateIds={setAssociateIds} setPage={setPage} />
      <UnreadChangesSection
        showUnreadChanges={showUnreadChanges}
        setShowUnreadChanges={setShowUnreadChanges}
        setPage={setPage}
      />
      <AssociateTable
        isLoading={isLoading}
        isErrored={isErrored}
        currentPage={page}
        setPage={setPage}
        onPageChange={(currentPage) => {
          setPage(currentPage);
        }}
        onSortChange={(ordering) => {
          setSort(ordering);
          setPage(0);
        }}
      />
    </PageLayout>
  );
}

export default Associates;
