import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BatchEditDrawer from '../../BatchEditDrawer/BatchEditDrawer';
import { setSelectedEditField } from '../../../state/ducks/staffingWorkPage/actions';
import AssociateDrawer from '../../AssociateDrawer/AssociateDrawer';
import { NO_ASSOCIATE } from '../../BatchEditDrawer/constants';

function Drawers({ gridApiRef }) {
  const [selectedAssociate, setSelectedAssociate] = useState(NO_ASSOCIATE);
  const selectedEditField = useSelector(
    (state) => state.staffingWorkPage.batch.selectedEditField
  );
  const dispatch = useDispatch();
  return (
    <>
      <BatchEditDrawer
        selectedAssociate={selectedAssociate}
        setSelectedAssociate={setSelectedAssociate}
        isOpen={!!selectedEditField}
        onClose={() => {
          dispatch(setSelectedEditField(null));
        }}
        editField={selectedEditField}
        gridApiRef={gridApiRef}
      />
      <AssociateDrawer
        setSelectedAssociate={setSelectedAssociate}
        gridApiRef={gridApiRef}
      />
    </>
  );
}

export default Drawers;
